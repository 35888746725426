<template>
  <section id="log-tte">
    <b-row>
      <b-col
        cols="12"
      >
        <b-card>
          <!-- table -->
          <vue-good-table
            mode="remote"
            :total-rows="totalRecords"
            :is-loading.sync="isLoading"
            :columns="columns"
            :rows="rows"
            :pagination-options="{
              enabled: true,
              perPage:serverParams.length,
              mode: 'pages',
              setCurrentPage:selectPage,
              perPageDropdown: [15, 30, 60, 100],
              dropdownAllowAll: false,
            }"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- Lihat Rekomendasi Direktur -->
              <span v-if="props.column.field == 'status'">
                {{ statusVariant(props.row.raw_data) }}
              </span>
              <span v-else-if="props.column.field === 'created_on'">
                {{ props.row.created_on | formatDate }}
              </span>
              <!-- Column: Action -->
              <span v-else-if="props.column.field == 'action'">
                <b-button
                  v-b-tooltip.hover
                  :to="{ name: 'buat-surat-rekomendasi', params: { id: props.row.clearance_id } }"
                  variant="primary"
                  size="sm"
                  class="m-25"
                  title="Buat Surat Rekomendasi"
                >
                  <feather-icon
                    icon="Edit3Icon"
                    size="12"
                  /></b-button>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </b-card>

      </b-col>
      <!-- post -->
    </b-row>
  </section>

</template>

<script>
import store from '@/store'
import {
  BRow, BButton, BCol, BCard, VBTooltip, VBModal,
} from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'

import 'vue-pdf-app/dist/icons/main.css'

export default {
  components: {
    BButton,
    BCard,
    BRow,
    BCol,
    VueGoodTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  beforeRouteEnter(to, from, next) {
    const role = localStorage.getItem('roleId')
    if (role !== '10') {
      next('/')
    } else {
      next()
    }
  },
  data() {
    return {
      fileSurat: '',
      isLoading: false,
      userData: getUserData(),
      referenceData: {
        ref_instansi: [],
      },
      columns: [
        {
          thClass: 'text-center',
          label: 'Date Time',
          field: 'created_on',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Penandatangan',
          field: 'fullname',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'No. Surat Kominfo',
          field: 'no_surat_kominfo',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'No. Surat Menpanrb',
          field: 'no_surat_panrb',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Status',
          field: 'status',
          sortable: false,
        },
      ],
      totalRecords: 0,
      selectPage: 1,
      serverParams: {
        sort_by: [
          {
            column: 'created_on',
            asc_desc: 'desc',
          },
        ],
        start: 0,
        length: 30,
      },
      rows: [],
      searchTerm: '',
      dataSaved: false,
      errorStat: false,
      errorMsg: '',
    }
  },
  mounted() {
    const { page, currentPage, currentPerPage } = store.state.app
    if (page === 'logs' && currentPage && currentPerPage) {
      this.selectPage = currentPage
      this.serverParams.length = currentPerPage
      this.updateParams({ start: (currentPage - 1) * this.serverParams.length })
    }
    this.doFilter()
    store.commit('app/SET_PAGE', 'logs')
  },
  methods: {
    statusVariant(status) {
      const err = JSON.parse(status)
      const msg = (err.error) ? err.error : err.message
      return `[Error ${err.status_code}] ${msg}`
    },
    doFilter() {
      const params = []
      this.updateParams({
        filters: params,
      })
      this.loadItems()
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      store.commit('app/CURRENT_PAGE', params.currentPage)
      this.selectPage = store.state.app.currentPage
      this.updateParams({ start: (params.currentPage - 1) * this.serverParams.length })
      this.loadItems()
    },
    onPerPageChange(params) {
      store.commit('app/CURRENT_PER_PAGE', params.currentPerPage)
      this.updateParams({ length: params.currentPerPage, start: (this.selectPage - 1) * params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      params.map(param => {
        this.updateParams({
          sort_by: [{
            asc_desc: param.type,
            column: param.field,
          }],
        })
        return true
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      const filterz = []
      Object.entries(params.columnFilters).forEach(entry => {
        const [key, valuez] = entry
        filterz.push({
          column: key,
          value: valuez,
        })
      })
      this.updateParams({
        filters: filterz,
      })
      this.loadItems()
    },
    loadItems() {
      this.rows = []
      this.$http.post(`/generate/error-log?token=${localStorage.getItem('userToken')}`, this.serverParams)
        .then(res => {
          if (res.data.status === 'success') {
            this.rows = res.data.data.data
            this.totalRecords = res.data.data.total_rows
          } else {
            this.rows = []
            this.totalRecords = 0
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
