var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"log-tte"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[_c('vue-good-table',{attrs:{"mode":"remote","total-rows":_vm.totalRecords,"is-loading":_vm.isLoading,"columns":_vm.columns,"rows":_vm.rows,"pagination-options":{
            enabled: true,
            perPage:_vm.serverParams.length,
            mode: 'pages',
            setCurrentPage:_vm.selectPage,
            perPageDropdown: [15, 30, 60, 100],
            dropdownAllowAll: false,
          }},on:{"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event},"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'status')?_c('span',[_vm._v(" "+_vm._s(_vm.statusVariant(props.row.raw_data))+" ")]):(props.column.field === 'created_on')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(props.row.created_on))+" ")]):(props.column.field == 'action')?_c('span',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"m-25",attrs:{"to":{ name: 'buat-surat-rekomendasi', params: { id: props.row.clearance_id } },"variant":"primary","size":"sm","title":"Buat Surat Rekomendasi"}},[_c('feather-icon',{attrs:{"icon":"Edit3Icon","size":"12"}})],1)],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }